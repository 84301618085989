@import url("https://p.typekit.net/p.css?s=1&k=evf2xmx&ht=tk&f=139.173.175.25136&a=9527591&app=typekit&e=css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

.overlay::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgba(32, 128, 32, 0.4) 2%,
    rgba(32, 128, 32, 1) 3%,
    rgba(32, 128, 32, 0.4) 3%,
    transparent 100%
  );
  background-repeat: no-repeat;
  animation: scan 2s linear 0s infinite;
  z-index: 1000;
}

.mint-button {
  background-color: #20B2AA; /* LightSeaGreen color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove default button border */
}

.mint-button span:after {
  content: '';
  animation: dots 1s steps(10, end) infinite;
}

@-webkit-keyframes blink {
  100% {
    box-shadow:  0 0 10px rgba(249, 140, 31, 1), 0 0 20px rgba(249, 140, 31, 1);
  }
}

@keyframes blink {
  100% {
    box-shadow:  0 0 10px rgba(249, 140, 31, 1), 0 0 20px rgba(249, 140, 31, 1);
  }
}

@keyframes dots {
  0% {
    content: '';
  }
  10% {
     content: 'M';
  }
  20% {
    content: 'MI';
  }
  30% {
    content: 'MIN';
  }
  40% {
    content: 'MINT';
  }
  50% {
    content: 'MINTI';
  }
  60% {
    content: 'MINTIN';
  }
  70% {
    content: 'MINTING';
  }
  80% {
    content: 'MINTING.';
  }
  90% {
    content: 'MINTING..';
  }
  100% {
    content: 'MINTING...';
  }
}

@keyframes scan {
  0% {
    background-position: 0 -128px;
  }
  10% {
    background-position: 0 -128px;
  }
  30% {
    background-position: 0 -64px;
  }
  80% {
    background-position: 0 128px;
  }
  100% {
    background-position: 0 128px;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  55% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0.7;
  }
  85% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  95% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  55% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0.7;
  }
  85% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  95% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  55% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0.7;
  }
  85% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  95% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  55% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0.7;
  }
  85% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  95% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 5s infinite;
  -moz-animation: flickerAnimation 5s infinite;
  -o-animation: flickerAnimation 5s infinite;
  animation: flickerAnimation 5s infinite;
}
