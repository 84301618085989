@keyframes flicker {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.1;
  }
  10% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0.95;
  }
  65% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.5;
  }
  85% {
    opacity: 0.2;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
